const isLocal = window.location.hostname === "localhost";
const backendHost = isLocal ? "localhost" : "157.230.69.115";
const backendPort = isLocal ? "8000" : "80";
const websocketUrl =  `ws://${backendHost}:${backendPort}/ws`;


let socket;
let isConnected = false;

function connectWebSocket(onMessageReceived, onConnected, onDisconnected) {
  console.log("Connecting to WebSocket:", websocketUrl);

  socket = new WebSocket(websocketUrl);

  socket.onopen = () => {
    console.log("WebSocket connection opened");
    isConnected = true;
    if (onConnected) onConnected();
  };

  socket.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.log("Message received:", message);
    if (onMessageReceived) onMessageReceived(message);
  };

  socket.onclose = () => {
    console.log("WebSocket connection closed");
    isConnected = false;
    if (onDisconnected) onDisconnected();
  };
}

function sendMessage(message) {
  if (isConnected && socket.readyState === WebSocket.OPEN) {
    socket.send(JSON.stringify(message));
  } else {
    console.error("WebSocket is not open. Message not sent:", message);
  }
}

export { connectWebSocket, sendMessage };
