import React, { useState, useEffect } from "react";
import CountdownComponent from "./CountdownComponent";
import "./GameUI.css";

// Define constants for colors
const SELECTED_COLOR = "#4caf50"; // Green for selected options
const DEFAULT_COLOR = "#f9f9f9"; // Default button background

function GameUI({ message, onAction }) {
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Reset states when a new message arrives
  useEffect(() => {
    if (message) {
      console.log("Rendering message in GameUI:", message);
      setInputValue(""); // Reset input value
      setSelectedOptions([]); // Reset selected options
    }
  }, [message]);

  // If no message, show a waiting state
  if (!message) {
    return <div>Waiting for game instructions...</div>;
  }

  const handleInputSubmit = () => {
    if (!inputValue.trim()) return; // Prevent empty submissions
    onAction({
      key: message.key,
      response: inputValue,
    });
  };

  const handleOptionToggle = (option) => {
    // Toggle option selection for multiselect
    setSelectedOptions(
      (prevSelected) =>
        prevSelected.includes(option)
          ? prevSelected.filter((opt) => opt !== option) // Remove if already selected
          : [...prevSelected, option] // Add if not already selected
    );
  };

  const handleMultiSelectSubmit = () => {
    if (selectedOptions.length === 0) return; // Prevent empty submission
    onAction({
      key: message.key,
      response: selectedOptions,
    });
  };

  const handleOptionSelect = (option) => {
    // Directly send single-option response
    onAction({
      key: message.key,
      response: option,
    });
  };

  const handleInfoButtonClick = (key) => {
    // Handle the button click in "info" message type
    console.log(`Info button clicked: ${key}`);
    onAction({
      key,
      response: key,
    });
  };

  switch (message.type) {
    case "info":
      return (
        <div className="info-message-container">
          {Array.isArray(message.prompt) ? (
            message.prompt.map((line, index) => (
              <p key={index} className="info-message-line">
                {line}
              </p>
            ))
          ) : (
            <p className="info-message-line">{message.prompt}</p>
          )}

          {/* Optional button for "info" type messages */}
          {message.button && (
            <button
              className="info-button"
              onClick={() => handleInfoButtonClick(message.button.key)}
            >
              {message.button.text}
            </button>
          )}
        </div>
      );

    case "prompt":
      return (
        <div className="prompt-container">
          <p>{message.prompt}</p>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <button onClick={handleInputSubmit}>Submit</button>
        </div>
      );

    case "optionPrompt":
      return (
        <div className="option-prompt-container">
          {Array.isArray(message.prompt) ? (
            message.prompt.map((line, index) => <p key={index}>{line}</p>)
          ) : (
            <p>{message.prompt}</p>
          )}

          <div className="options-container">
            {message.options.map((option) => (
              <button
                key={option}
                onClick={
                  message.multiSelect
                    ? () => handleOptionToggle(option)
                    : () => handleOptionSelect(option)
                }
                disabled={message.disableOptions}
                style={{
                  backgroundColor: selectedOptions.includes(option)
                    ? SELECTED_COLOR
                    : DEFAULT_COLOR,
                  color: selectedOptions.includes(option) ? "white" : "black",
                }}
                className={
                  selectedOptions.includes(option) ? "selected-option" : ""
                }
              >
                {option}
              </button>
            ))}
          </div>

          {/* Show "OK" button only for multiselect */}
          {message.multiSelect && (
            <button
              className="multi-select-submit-button"
              onClick={handleMultiSelectSubmit}
            >
              OK
            </button>
          )}

          {/* Show submit button for non-multiselect prompts */}
          {message.submitButton && !message.multiSelect && (
            <button
              className="single-submit-button"
              onClick={handleInputSubmit}
            >
              {message.submitButton}
            </button>
          )}
        </div>
      );

    case "countdown":
      return <CountdownComponent duration={message.duration} />;

    case "gameOver":
      return (
        <div className="game-over-container">
          <h2>Game Over</h2>
          <ul>
            {Object.entries(message.scores).map(([player, score]) => (
              <li key={player}>
                {player}: {score}
              </li>
            ))}
          </ul>
        </div>
      );

    default:
      console.error("Unhandled message type in render:", message.type);
      return <div>Unexpected message type received...</div>;
  }
}

export default GameUI;
