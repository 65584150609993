import React, { useState, useEffect } from 'react';

function CountdownComponent({ duration }) {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    const timer =
      timeLeft > 0 &&
      setInterval(() => {
        setTimeLeft((t) => t - 1);
      }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  return (
    <div>
      <p>Countdown: {timeLeft}</p>
    </div>
  );
}

export default CountdownComponent;
