import React, { useEffect, useState } from "react";

function SoundPlayer({ soundMessage }) {
  const [soundStore, setSoundStore] = useState({}); // State to store sounds by id

  useEffect(() => {
    if (soundMessage) {
      console.log("Received soundMessage:", soundMessage);

      const { id, soundData, defer } = soundMessage;

      try {
        if (id && soundData) {
          // Decode base64 audio data
          const byteCharacters = atob(soundData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "audio/mp3" });
          const audioUrl = URL.createObjectURL(blob);

          // Store the sound in the store
          setSoundStore((prevStore) => {
            // Revoke old URL if id already exists to prevent memory leaks
            if (prevStore[id]) {
              URL.revokeObjectURL(prevStore[id]);
            }
            return { ...prevStore, [id]: audioUrl };
          });

          if (!defer) {
            // Play the sound immediately unless deferred
            const audio = new Audio(audioUrl);
            audio.play().catch((err) => {
              console.error("Failed to play audio:", err);
            });
          }
        } else if (id && !soundData) {
          // Play a previously stored sound
          const storedAudioUrl = soundStore[id];
          if (storedAudioUrl) {
            const audio = new Audio(storedAudioUrl);
            audio.play().catch((err) => {
              console.error("Failed to play deferred sound:", err);
            });
          } else {
            console.error("Sound with ID not found in store:", id);
          }
        } else if (soundData) {
          // Play the sound directly without storing it
          const byteCharacters = atob(soundData);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "audio/mp3" });
          const audioUrl = URL.createObjectURL(blob);

          // Play the audio
          const audio = new Audio(audioUrl);
          audio.play().catch((err) => {
            console.error("Failed to play audio:", err);
          });

          // Revoke the object URL after playing
          audio.onended = () => {
            URL.revokeObjectURL(audioUrl);
          };
        }
      } catch (error) {
        console.error("Error processing soundMessage:", error);
      }
    }
  }, [soundMessage]);

  return null; // This component doesn't render anything visible
}

export default SoundPlayer;
