import React, { useEffect, useState, useRef } from "react";
import GameUI from "./components/GameUI";
import SoundPlayer from "./components/SoundPlayer"; // Import the SoundPlayer component
import { connectWebSocket, sendMessage } from "./services/websocket";
import "./App.css";

function App() {
  const [message, setMessage] = useState(null);
  const [soundMessage, setSoundMessage] = useState(null); // State for all sound messages
  const [connected, setConnected] = useState(false);
  const [playerName, setPlayerName] = useState("");
  const [tokenGuid, setTokenGuid] = useState(() => {
    const saved = localStorage.getItem("tokenGuid");
    return saved ? JSON.parse(saved) : {};
  });

  const connectionAttempted = useRef(false);
  const socketRef = useRef(null);

  useEffect(() => {
    if (connectionAttempted.current) return;

    const onMessageReceived = (msg) => {
      console.log("Message received from WebSocket:", msg);
      if (msg.type === "sound") {
        setSoundMessage(msg); // Pass all sound messages to SoundPlayer
      } else {
        setMessage(msg);
      }
    };

    const onConnected = () => {
      console.log("WebSocket connected");
      setConnected(true);
      connectionAttempted.current = true;

      if (tokenGuid.guid && tokenGuid.token && playerName) {
        sendMessage({
          key: "reconnect",
          content: { playerName, guid: tokenGuid.guid, token: tokenGuid.token },
        });
      }
    };

    const onDisconnected = () => {
      console.log("WebSocket disconnected");
      setConnected(false);
      connectionAttempted.current = false;

      setTimeout(() => {
        if (!connected) {
          socketRef.current = connectWebSocket(
            onMessageReceived,
            onConnected,
            onDisconnected
          );
          connectionAttempted.current = true;
        }
      }, 1000);
    };

    socketRef.current = connectWebSocket(
      onMessageReceived,
      onConnected,
      onDisconnected
    );

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        connectionAttempted.current = false;
      }
    };
  }, [playerName, tokenGuid]);

  const handleUserAction = (action) => {
    console.log("User action:", action);
    if (action.key === "setName") {
      setPlayerName(action.response);
    }
    sendMessage({
      key: action.key,
      response: action.response,
    });
  };

  return (
    <div className="App">
      {connected ? (
        <>
          <GameUI
            message={message}
            onAction={handleUserAction}
            playerName={playerName}
            tokenGuid={tokenGuid}
          />
          <SoundPlayer soundMessage={soundMessage} />
        </>
      ) : (
        <p>Connecting to server...</p>
      )}
    </div>
  );
}

export default App;